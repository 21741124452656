import React, {Component} from "react"
import Layout from "../components/layout"
import TextField from "@material-ui/core/TextField/TextField"
import Button from "@material-ui/core/Button"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import WaveSvg from "../assets/images/Wave.svg"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import {getFirstContentfulNode} from "../common/utils/QueryUtil"
import {isValidContentfulImage, isValidPhone, isValidSectionField} from "../common/utils/CommonUtils"
import {graphql, Link} from "gatsby"
import SEO from "../components/seo"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import BreadCrumbs from "../components/bread-crumbs";
import Rating from "@material-ui/lab/Rating";
import {BranchUtils} from "../common/utils/BranchUtils";
import "./courses.scss";


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started",
    },


]

class ConfidantProviders extends Component {

    constructor(props) {

        super(props);
        this.state = {
            phone: "",
            success: false,
            entities: [],
        };
        this.pageData = getFirstContentfulNode(this.props,
            "allContentfulProvidersLandingPage")
    }

    componentDidMount() {
        window.analytics.page("Provider Landing Page")
    }

    sendPhone(event, phoneNumber) {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {
            SegmentAnalytics.track(
                SegmentEvents.USER_REQUESTED_FOR_APP_FROM_PROVIDER_PAGE, {
                    phone,
                    'formLocation': 'provider-landing-page',
                    appRequested: true,
                });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': 'provider-landing-page',
                'event': 'GET_THE_APP',
                'phone': phone
            });

            let fromPage = 'provider-landing-page';

            BranchUtils.desktopSendSMS(phone, fromPage, 'Header CTA', 'provider-landing-page');

            this.setState({
                success: true,
            })
        } else {
            console.log("this.state.error", this.state.error);
            this.setState({
                error: true,
                success: false,
                phone: "",
            })
        }
    };

    navigateToProviderDetail = (slug) => {
        window.location.assign(`/online-therapy/providers/${slug}/`);
    };

    render() {
        const {topSection, shortSections, providers} = this.pageData;
        return (
            <Layout location={this.props.location}>
                <SEO title="Providers"/>
                <BreadCrumbs currentPage={"Online Therapy"} breadCrumbsList={breadCrumbsList}/>
                <div className="new-common-top-header">
                    <div className="new-common-top-inner">
                        {
                            isValidContentfulImage(topSection.imageOrVideo) && (
                                <img
                                    className="new-common-top-hero-img provider-details"
                                    src={topSection.imageOrVideo.file.url}
                                    alt="iPhone"/>
                            )
                        }
                        <div
                            className="new-common-top-pattern-content provider-content">
                            {
                                isValidSectionField(topSection, "title") &&
                                <h2 className="new-common-top-main-head">{topSection.title}</h2>
                            }
                            {

                                isValidSectionField(topSection, "description")
                                && (
                                    <p className="new-common-top-sub-head"
                                       dangerouslySetInnerHTML={{__html: topSection.description.description}}>
                                    </p>
                                )
                            }
                            <form method="post" onSubmit={(event) => {
                                this.sendPhone(event, this.state.phone)
                            }}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="phone-input"
                                            className="number-field"
                                            placeholder="Enter your phone number"
                                            value={this.state.phone}
                                            margin="normal"
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    phone: value,
                                                    error: false,
                                                    success: false,
                                                })
                                            }}
                                        />
                                    </div>
                                    <Button
                                        className="blue-arrow-btn"
                                        color="primary"
                                        type="submit"
                                    >
                                        Get the App <ArrowNavigationIcon/>
                                    </Button>
                                    {this.state.error ? <p
                                        className="error-msg">Please Enter Valid
                                        Phone </p> : null}
                                    {this.state.success ? <p
                                        className="success-msg">Got it! Sending you a text with the link </p> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <div className="common-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <img className="confetti-bg"
                         src={require("../assets/images/confeti-pattern.svg")}
                         alt="Pattern"/>
                    <div className="wave-bg"
                         style={{paddingLeft: 0, paddingRight: 0}}>
                        <div className="common-wave-inner">
                            <h2 className="common-wave-heading">Meet the right
                                provider for you</h2>
                            <p className="common-wave-des">It can be hard to
                                find the right provider.
                                We share profiles on each of our provider’s,
                                including their background, approach,
                                and area of expertise, so you can select the
                                provider you'd like to meet with.
                                If you're still not sure who is best for you,
                                try chatting with a Matchmaker.
                                Confidant Matchmakers serve as care navigators
                                on the Confidant app and can help
                                connect you with the services that meet your
                                needs</p>
                            <div className="common-wrap">
                                <div className="common-list">
                                    {
                                        providers.map((provider, key) => {
                                            return (
                                                <div key={key}
                                                     className="single-provider">
                                                    <div
                                                        className="single-provider-header">
                                                        <div
                                                            className="single-provider-header-img">
                                                            {provider.providerImage
                                                            !== null ?
                                                                <img
                                                                    src={'https:'
                                                                    + provider.providerImage?.file?.url}
                                                                    alt="providerImg"
                                                                    onClick={() => this.navigateToProviderDetail(
                                                                        provider.slug)}
                                                                />
                                                                :
                                                                <img
                                                                    src={require(
                                                                        '../assets/images/default-provider-image.png')}
                                                                    alt="providerImg"
                                                                    onClick={() => this.navigateToProviderDetail(
                                                                        provider.slug)}
                                                                />
                                                            }
                                                        </div>
                                                        <div
                                                            className="single-provider-header-detail">
                                                            <h3 onClick={() => this.navigateToProviderDetail(
                                                                provider.slug)}>{provider.providerName}</h3>
                                                            <p>{provider.role}</p>
                                                            <a className="view-pro"
                                                               href={`/online-therapy/providers/${provider.slug}/`}>View
                                                                full
                                                                profile <img
                                                                    src={require(
                                                                        "../assets/images/Right.svg")}
                                                                    alt="Icon"/></a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="single-rating-section">
                                                        <div
                                                            className='single-rating-section-rating'>
                                                            <Rating
                                                                name="read-only"
                                                                value={provider.rating}
                                                                readOnly
                                                                size='small'/>
                                                            <p className="rating-text">{provider.rating}</p>
                                                        </div>
                                                        <div
                                                            className="single-rating-section-totalViews">
                                                            {provider.totalReviews} reviews
                                                        </div>

                                                    </div>
                                                    <div
                                                        className="single-provider-body">
                                                        <p className="single-provider-bio">
                                                            {provider.description?.description}
                                                        </p>
                                                        <Link
                                                            className="outline-btn"
                                                            to={`/online-therapy/providers/${provider.slug}/`}
                                                        >Connect</Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Button href="/online-therapy/providers/"
                                    className="new-blue-link" color="primary">
                                See all of the Confidant
                                Providers <ArrowForwardThinIcon/>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="common-feature-wrapper">
                    <div className="common-feature-inner">
                        <h2 className="common-feature-head">What to expect from
                            virtual care with Confidant</h2>
                        <p className="common-feature-des">We built the Confidant
                            app and our provider network from the
                            ground up to support high-quality addiction care for
                            individuals and caregivers.</p>
                        <p className="common-feature-des">1 in 3 Americans have
                            a family member or close friend that is
                            impacted by addiction but historically, most people
                            don't get help, and if they do,
                            it's usually very late in the addiction cycle. With
                            Confidant,
                            virtual addiction care is easy to access,
                            affordable,
                            and will help you reach your goals.</p>

                        {
                            shortSections && shortSections.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="common-feature-single-feature">
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="common-feature-img-side">
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="common-feature-image"
                                                            src={section.image.file.url}
                                                            alt="Frame"/>
                                                    )
                                                    }

                                                </div>
                                            )
                                        }
                                        <div
                                            className="common-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&
                                                <h2 className="common-feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }
                                            {
                                                isValidSectionField(section,
                                                    "description") &&
                                                <p className="common-feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}>
                                                </p>
                                            }
                                        </div>
                                        {
                                            (index === 0 || index % 2 === 0)
                                            && (
                                                <div
                                                    className="common-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="common-feature-image"
                                                            src={section.image.file.url}
                                                            alt=" Frame"/>
                                                    )
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                        }
                    </div>
                </div>
            </Layout>
        )
    }

}

export default ConfidantProviders

export const query = graphql`
query providersLandingPage{
  allContentfulProvidersLandingPage {
    nodes {
      topSection {
        title
               ... on ContentfulPageTopSection {
        description {
          description
        }
        imageOrVideo {
          file {
            contentType
            url
          }
        }
      }
      }
      shortSections {
       ... on ContentfulShortSection{
          alignment
          heading
          image {
            file {
              contentType
              url
            }
          }
          description {
            description
          }
          link
          linkText
        }
    }
     providers {
      id
      slug
      role
      providerName
      providerImage {
        file {
          contentType
          url
        }
      }
      totalReviews
      rating
      description {
        description
      }}
    }
  }
}`;

